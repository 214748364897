<template>
  <div>
    <van-nav-bar
      title="家庭成员"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="detailsTop">
      <i><img src="@/assets/img/home.png" alt="" /></i>
      <div>
        <div>
          <span>
            {{ this.$route.query.v.familyMemberCode }}
          </span>
          <span>
            {{ this.$route.query.v.name }}
          </span>
        </div>
        <div>
          <span>
            与户主关系:
            {{
              common.gettypeListLabelFn(
                "holdcontactList",
                this.$route.query.v.householdRelationship
              )
            }}
            {{ this.$route.query.v.relation }}
          </span>
          <span>
            {{ common.gettypeListLabelFn("sexList", this.$route.query.v.sex) }}
          </span>
        </div>
      </div>
    </div>
    <div class="basicBox" v-if="this.$route.query.v">
      <div class="codeNum">
        <div>证件号码</div>
        <div>
          {{
            this.$route.query.v.disabilityCard
              ? this.$route.query.v.disabilityCard
              : this.$route.query.v.idCard
          }}
        </div>
      </div>
      <div class="codeNum">
        <div>残疾类别</div>
        <div>
          {{
            common.gettypeListLabelFn(
              "disabilityTypeList",
              this.$route.query.v.disabilityCategory
            )
          }}
        </div>
      </div>
      <div class="codeNum">
        <div>联系电话</div>
        <div>
          {{ this.$route.query.v.phone || this.$route.query.v.phoneNumber }}
        </div>
      </div>
      <div class="codeNum">
        <div>民族</div>
        <div>{{ common.gettypeListLabelFn("nationList", this.$route.query.v.nation) }}</div>
      </div>
      <div class="codeNum">
        <div>政治面貌</div>
        <div>
          {{ common.gettypeListLabelFn("faceList", this.$route.query.v.politicalStatus) }}
        </div>
      </div>
      <div class="codeNum">
        <div>文化程度</div>
        <div>{{ common.gettypeListLabelFn("cultureList", this.$route.query.v.education) }}</div>
      </div>
      <div class="codeNum">
        <div>在校生状况</div>
        <div>
          {{
            common.gettypeListLabelFn(
              "schoolList",
              this.$route.query.v.studentStatus
            )
          }}
        </div>
      </div>
      <div class="codeNum">
        <div>健康状况</div>
        <div>
          {{ common.gettypeListLabelFn("healthyList", this.$route.query.v.healthStatus) }}
        </div>
      </div>
      <div class="codeNum">
        <div>劳动技能</div>
        <div>{{ common.gettypeListLabelFn("skillList", this.$route.query.v.laborSkills) }}</div>
      </div>
      <div class="codeNum">
        <div>务工区域</div>
        <div>{{ this.$route.query.v.workArea }}</div>
      </div>
      <div class="codeNum">
        <div>务工时间</div>
        <div>{{ this.$route.query.v.workingTime }}个月</div>
      </div>
      <div class="codeNum">
        <div>是否参加城乡居民基本医疗保险</div>
        <div>{{ this.$route.query.v.isRuralMedicareInsurance ? "是" : "否" }}</div>
      </div>
      <div class="codeNum">
        <div>是否参加城乡居民基本养老保险</div>
        <div>{{ this.$route.query.v.isRuralEndowmentInsurance ? "是" : "否" }}</div>
      </div>
      <div class="codeNum">
        <div>是否参加大病保险</div>
        <div>{{ this.$route.query.v.isMajorIllnessInsurance ? "是" : "否" }}</div>
      </div>
      <div class="codeNum">
        <div>是否享受农村居民最低生活保障</div>
        <div>{{ this.$route.query.v.isSubsistenceAllowance ? "是" : "否" }}</div>
      </div>
      <div class="codeNum">
        <div>是否特困供养人员</div>
        <div>{{ this.$route.query.v.isDestituteSupporter ? "是" : "否" }}</div>
      </div>
      <div class="codeNum">
        <div>产业发展或创业情况</div>
        <div>{{ this.$route.query.v.developmentStatus }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created(){
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    goOpinion() {
      this.$router.push("/opinion");
    },
  },
  mounted() {},
};
</script>

<style scoped>
.detailsTop {
  display: flex;
  justify-content: space-between;
  width: 94%;
  margin: 0 auto;
  height: 0.6rem;
  line-height: 0.3rem;
  border-bottom: 2px solid #eee;
  font-weight: bold;
  color: #666;
}
.detailsTop > i {
  width: 0.25rem;
  height: 0.25rem;
}
.detailsTop > i > img {
  width: 0.25rem;
  height: 0.25rem;
  vertical-align: middle;
}

.basicBox > div {
  border-bottom: 1px solid #eee;
  width: 94%;
  height: 0.5rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.basicBox > div > div {
  line-height: 0.5rem;
}

/* vant 导航栏自适应 */
.van-nav-bar {
  height: 0.46rem;
}

>>> .van-nav-bar__content {
  height: 100%;
}

>>> .van-nav-bar__title {
  height: 100%;
  line-height: 0.46rem;
  font-size: 0.16rem;
}

>>> .van-icon-arrow-left,
>>> .van-nav-bar__text {
  font-size: 0.14rem;
}
.detailsTop > div {
  width: 80%;
}
.detailsTop > div > div {
  height: 50%;
  display: flex;
  justify-content: space-between;
}
.basicBox{
  margin-bottom: 0.5rem;
}
</style>